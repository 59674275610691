var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-wrapper"},[_c('BaseNavigation'),_c('DividerNavigation'),_c('div',{staticClass:"p-2 mx-auto"},[_c('br'),_c('div',[_c('b-card',{staticClass:"shadow-none border mb-1",attrs:{"no-body":""}},[_c('div',{staticClass:"d-flex p-1 border-bottom",class:_vm.webinarDetails.data.webijadwal_status !== 'pendaftaran-dibuka'
              ? 'bg-warning text-white'
              : ''},[_c('div',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" #KEGIATAN-"+_vm._s(_vm.webinarDetails.data.id)+" ")]),(
                _vm.webinarDetails.data.webijadwal_status !== 'pendaftaran-dibuka'
              )?_c('b-badge',{attrs:{"variant":"light-danger font-weight–light mt-25"}},[_vm._v(" Pendaftaran Sudah Ditutup ")]):_vm._e()],1),_c('div',{staticClass:"ml-auto pointer"},[(
                _vm.webinarDetails.data.webijadwal_status === 'pendaftaran-dibuka'
              )?_c('b-button',{staticClass:"bg-primary bg-lighten-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.daftarWebinar(_vm.webinarDetails.data.id)}}},[_vm._v(" Daftar ")]):_vm._e()],1)]),_c('div',{staticClass:"pb-1 pt-1"},[_c('b-img',{staticClass:"mb-25",attrs:{"src":_vm.getWebinarImg(_vm.webinarDetails.data),"fluid":"","width":"340","height":"300","center":""}})],1),_c('div',{staticClass:"p-1"},[_c('table',[_c('tbody',[_c('tr',[_c('td',[_vm._v("Title")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" : "+_vm._s(_vm.webinarDetails.data.webijadwal_title)+" ")])]),_c('tr',[_c('td',[_vm._v("Tanggal")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" : "+_vm._s(_vm.webinarDetails.data.webijadwal_tanggal)+" ")])]),_c('tr',[_c('td',[_vm._v("Jam")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" : "+_vm._s(_vm.webinarDetails.data.webijadwal_jam)+" ")])]),_c('tr',[_c('td',[_vm._v("Limit / Durasi")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" : "+_vm._s(_vm.webinarDetails.data.webijadwal_durasi)+"/ "+_vm._s(_vm.webinarDetails.data.webijadwal_limit)+" Menit ")])]),_c('tr',[_c('td',[_vm._v("Status")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" : "+_vm._s(_vm.webinarDetails.data.webijadwal_status)+" ")])]),(
                  _vm.webinarDetails.data.webijadwal_link &&
                  _vm.webinarDetails.data.webijadwal_status ===
                    'pendaftaran-dibuka'
                )?void 0:_vm._e()],2)])])]),(_vm.webinarDetails.isLoading)?_c('div',{staticClass:"d-flex justify-content-center mb-1"},[_c('b-spinner',{attrs:{"label":"Loading...","variant":"danger"}})],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }